@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap');
/* font-family: 'Goudy Bookletter 1911', serif; */

body {
    margin: 25px;
}

h1,h2,h3,h4,h5 {
    font-family: 'Goudy Bookletter 1911', serif;
}

p, a{
    font-family: 'Goudy Bookletter 1911', serif;
    font-size: 1.5rem;
}

a {
    color: black;
}

a:hover {
    color: black;
}

ul {
    list-style-type: none;
}

h1 {
    line-height: 0.7rem;
    margin-left: 8.5rem;
    display: block;
}

header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 1rem;
}

hr {
    border-top: 2px solid black;
}

.hidden { display: none }

.titleText {
    margin-right: 2rem; 
    flex: none
}

.banner {
    flex-grow: 2;
}

.headerpic {
    width: 100%;
    height: 9rem;
}

.titleStart {
    margin-left: 0rem;
}

.titleEnd {
    margin-left: 6.2rem;
}

.ring {
    min-height: 33vh;
}

footer {
    bottom: 0px;
}

.explainer {
    margin-right: 30%;
}

/* Portrait */
@media only screen
	and (min-device-width: 375px)
	and (max-device-width: 812px)
	and (-webkit-min-device-pixel-ratio: 3)
	and (orientation: portrait) {

}

/* @media all and (max-width: 1690px) { ...} */
/* @media all and (max-width: 1280px) { ...} */
/* @media all and (max-width: 980px) { ... } */
/* @media all and (max-width: 736px) { ... } */
@media all and (max-width: 600px) {
    ul { padding-left: 0 }
    .titleEnd { margin-left: 0.2rem }

    h1 {
        font-size: 1.4rem;
        margin-left: 2rem;
    }

    p, a { font-size: 1.1rem }
}
@media all and (max-width: 950px) {
    header {
        flex-direction: column;
    }
}
@media all and (max-width: 650px) {
    .explainer {
        margin-right: 0;
    }
}

/* @media all and (max-height: 740px) {
}
@media all and (max-height: 480px) {

} */
